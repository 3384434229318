<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="参数名称" prop="configName">
        <a-input v-model="form.configName" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="参数键名" prop="configKey">
        <a-input v-model="form.configKey" placeholder="请输入" disabled/>
        <!-- <a-input v-model="form.configKey" placeholder="请输入" /> -->
      </a-form-model-item>
      <!-- <a-form-model-item label="系统内置" prop="configType">
        <a-radio-group v-model="form.configType" button-style="solid">
          <a-radio-button v-for="(d, index) in typeOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item> -->
      <!-- <a-form-model-item label="内容类型">
        <a-radio-group v-model="form.remark" button-style="solid">
          <a-radio-button v-for="(d, index) in [{name:'单行文本',id:1},{name:'富文本',id:2}]" :key="index" :value="d.id">
            {{ d.name }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item> -->
      <a-form-model-item label="参数键值" prop="configValue" v-if="form.remark == 1">
        <a-input v-model="form.configValue" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="内容" prop="configValue" v-if="form.remark == 2">
        <editor v-model="form.configValue" />
      </a-form-model-item>
      <!-- <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入备注" type="textarea" allow-clear />
      </a-form-model-item> -->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { getConfig, addConfig, updateConfig } from '@/api/system/config'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
    typeOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      conType: 1,
      // 表单参数
      form: {
        id: 0,
        configName: '',
        configKey: '',
        configValue: '',
        configType: 'Y',
        remark: ''
      },
      open: false,
      rules: {
        configName: [{ required: true, message: '参数名称不能为空', trigger: 'blur' }],
        configKey: [{ required: true, message: '参数键名不能为空', trigger: 'blur' }],
        configValue: [{ required: true, message: '参数键值不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: 0,
        configName: '',
        configKey: '',
        configValue: '',
        configType: 'Y',
        remark: ''
      }
    },
     /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      this.open = true
      this.formTitle = '添加参数'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      const configId = row ? row.id : ids
      getConfig(configId).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改参数'
      })
    },
    /** 提交按钮 */
    submitForm () {
      const that = this
      this.$refs.form.validate(valid => {
        if (valid) {
          that.submitLoading = true
          if (that.form.id) {
            updateConfig(that.form).then(response => {
              that.$message.success(
                '修改成功',
                3
              )
              that.open = false
              that.$emit('ok')
            }).finally(() => {
              that.submitLoading = false
            })
          } else {
            addConfig(that.form).then(response => {
              that.$message.success(
                '新增成功',
                3
              )
              that.open = false
              that.$emit('ok')
            }).finally(() => {
              that.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
